<template>
    <div>
        <div :class="{'nav':true,'rotate-90':show}">
            <van-icon class="icon" name="apps-o" @click="showPanel"/>
        </div>
        <transition name="zoom" mode="out-in">
            <div class="unfold" v-if="show">
                <div :class="{'block-nav':true,'block-active':opacityTag}" @click="backSystemHome">
                    <div><van-icon class="icon" name="graphic" /></div>
                    <div class="name">主界面</div>
                </div>
                <div :class="{'block-nav':true,'block-active':opacityTag}" @click="backCurrentProject">
                    <div><van-icon class="icon" name="notes-o" /></div>
                    <div class="name">项目列表</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data(){
        return{
            opacityTag:false,
            show:false,
            timeOutEvent: 0,
            longClick: 0,
            // 手指原始位置
            oldMousePos: {},
            // 元素原始位置
            oldNodePos: {},
        }
    },
    methods:{
        // 添加标识位异步消除-动画改变width导致元素内堆积的bug
        showPanel(){
            if(!this.show){
                // from
                this.show = !this.show;
                setTimeout(()=>{
                    this.opacityTag = !this.opacityTag;
                },200)
            }else{
                // to
                this.opacityTag = !this.opacityTag;
                setTimeout(()=>{
                    this.show = !this.show;
                },100)
            }
        },
        // 返回系统首页
        backSystemHome(){
            this.$router.push({path:'/'})
        },
        // 返回项目首页
        backCurrentProject(){
            const path = this.$route.matched[0].path.replace('/','')
            this.$router.push({path:'/project',query:{path:path}})
        }
    }
}
</script>

<style lang="scss" scoped>
.nav{
    position: fixed;
    top: 5%;
    right: 2%;
    background: rgb(16, 142, 211);
    color: #fff;
    border-radius: 50%;
    z-index: 99;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .2s;
    .icon{
        font-size: 1.5rem;
    }
}
.rotate-90{
    transform: rotate(90deg);
}
.unfold{
    position: fixed;
    top: 5%;
    right: 2%;
    background: #ececec;
    border-radius: 30px;
    // padding: 8px;
    // height: 45px;
    // width: 125px;
    // padding: 0 40px 0 20px;
    width: 45px;
    height: 105px;
    padding: 40px 0 20px 0;
    display: flex;
    z-index: 98;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 1px 5px 2px rgb(0 0 0 / 30%);
    .block-nav{
        // padding-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all .2s;
        .icon{
            font-size: 1.25rem;
        }
        .name{
            font-size: .6rem;
        }
        &:active{
            color: rgb(16, 142, 211);
        }
    }
    .block-active{
        opacity: 1;
    }
}
/** 动画进行时的class **/
.zoom-enter-active, .zoom-leave-active {
	transition: all .1s;
}
			
/** 设置进场开始的状态和离场结束的状态 **/
.zoom-enter, .zoom-leave-to {
	// width: 0px;
    height: 0px;
}
			
/** 设置进场结束的状态和离场开始的状态 **/
.zoom-enter-to, .zoom-leave {
	// width: 125px;
	height: 105px;
}
</style>