<template>
    <div class="wlms">
        <router-view/>
        <Nav />
    </div>
</template>

<script>
import Nav from '@/components/nav.vue'
export default {
    components:{
        Nav:Nav
    },
    data(){
        return{}
    }
}
</script>

<style lang="scss">
@import '../../assets/wlms/index.scss';
.amap-copyright,.amap-logo{display: none !important;}
.amap-marker-label{color: #443535;border: 1px solid #d2d2d2 !important;}
.list{
    .alarm{color:#de8383 !important;font-weight: bold;}
    .online{color:#15bfc7 !important;font-weight: bold;}
    .offline{color: #717171 !important;font-weight: bold;}
}
.setting{
    .van-field__label{
        width: 12.2em !important;
    }
}
</style>>
